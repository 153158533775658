import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import { AppDispatch, AppState } from 'store/configureStore';
import { setAccountVerificationFormActive } from 'store/system/systemActions';

const AccountVerificationFormContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFormOpen = useSelector((state: AppState) => state.app.system.isAccountVerificationFormOpen);

  /**
   * Sets the system property `isAccountVerificationFormOpen` to false, which closes the global dialog
   */
  const onClose = useCallback(() => dispatch(setAccountVerificationFormActive(false)), [dispatch]);

  return (
    <ConfirmDialog isOpen={isFormOpen} onClose={onClose} title="Account Verification">
      <p>TODO</p>
    </ConfirmDialog>
  );
};

export default AccountVerificationFormContainer;
