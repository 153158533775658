import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import InventoryItem from 'constants/inventoryItem';
import {
  LocationConnection,
  MutationinventoryItemUpdateArgs,
  Note,
  Vehicle,
  VehicleBuilder,
  VehicleStatus,
  QuerylocationConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { SelectCompanyOption } from 'forms/shared/selectCompany';
import { inventoryItemFees, inventoryItemValues } from 'store/shared/api/graph/schema/inventoryItem';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { nullifyEmptyString } from 'utils/stringUtils';
import { repsFragment } from 'store/shared/api/graph/fragments/reps';

export const getInventoryItemDetails = ({ inventoryItemId }) => {
  return ApiRequest<{ inventoryItem: InventoryItem }>({
    data: {
      operationName: 'getInventoryItemDetails',
      query: `
        query($inventoryItemId: String!) {
          inventoryItem(inventoryItemId: $inventoryItemId) {
            id
            auctionLocation {
              ...location
            }
            availableAuctions {
              id
            }
            auctionTimeSlotLane {
              auctionTimeSlotId
              id
              name
              type
            }
            runNumber
            created
            createdBy {
              ...repUser
            }
            capturedBy {
              ...repUser
            }
            captured
            updated
            pickupLocation {
              ...location
            }
            stockNumber
            description
            company {
              id
              numericId
              name
              parentCompany {
                id
              }
              primaryLocation {
                regionCode
              }
            }
            complete
            completionPercentage
            incompleteFieldMessages {
              field
              message
            }
            isUpdatable
            archived
            ... on Vehicle {
              autocheckExceptions {
                date
                description
                stateCode
              }
              autocheckExceptionsReceived
              blueDriverScanData {
                blueDriverSmogScanEvent {
                  result
                }
                blueDriverCodeScanEventsCount
                codeScanCompleted
                smogScanCompleted
              }
              captureType
              status
              verifiedBy
              year
              make
              model
              subModel
              notes {
                id
                content
                created
                createdBy {
                  firstName
                  lastName
                }
              }
              description
              trim
              vin
              stockNumber
              bodyType
              chargingCable
              numberOfDoors
              numberOfPassengers
              exteriorColor
              interiorColor
              transmission
              cylinders
              displacement
              driveTrain
              fuelType
              asIsCause
              autoGradeScore
              autoGradeScoreData {
                autoGradeVehicleDamageCount
                autoGradeVehicleDamage {
                  damageCodeRecord {
                    id
                    sectionNumber
                    sectionName
                    itemCode
                    itemName
                    damageName
                    damageCode
                    severityName
                    severityCode
                  }
                  photos {
                    id
                    location
                    ...photoFrag
                  }
                }
              }
              selectedOptions: options(selected: true) {
               name
              }
              photos(type: EXTERIOR) {
               ...photoFrag
              }
              undercarriagePhotos: photos(type: UNDERCARRIAGE) {
               ...photoFrag
              }
              interiorPhotos: photos(type: INTERIOR) {
               ...photoFrag
              }
              damagePhotos: photos(type: DAMAGE) {
               ...photoFrag
               location
              }
              location {
                 ...location
               }
              conditionReport {
               ...conditionFrag
              }
              paintCondition {
               ...paintConditionFrag
              }
              tireCondition {
               ...tireConditionFrag
              }
              mileage {
               formattedAmount
              }
              lastKnownMileage {
                formattedAmount
              }
              lastKnownMileageDate
              sellerNotes
              sellerReps {
                ...reps
              }
              drivetrainBuyerShield
              engineBuyerShield
              transmissionBuyerShield
              vehicleBuyerShield
              extensiveVehicleConditionScore
              exteriorNote
              exteriorRating
              interiorNote
              interiorRating
              legalSaleDate
              mechanicalDrivetrainNote
              mechanicalDrivetrainRating
              mechanicalEngineNote
              mechanicalEngineRating
              mechanicalNote
              mechanicalRating
              mechanicalTransmissionNote
              mechanicalTransmissionRating
              source
              tiresNote
              tiresRating
              transportDetails {
                id
                value
              }
              printableUrl
              ${inventoryItemFees}
              ${inventoryItemValues}
            }
          }
        }
        fragment photoFrag on Photo {
          main: url (width: 800, height: 600)
          expanded: url (width: 1200, height: 768)
          thumb: url (width: 160, height: 130)
        }
        fragment conditionFrag on VehicleConditionReport {
          overallConditionRating
          carfaxCanadaReportUrl
          carfaxCanadaClaimAmount {
            amount
            formattedAmountRounded
          }
          carfaxCanadaDisclosuresExist
          repaintOnHowManyPanels
          activeDeclarations: declarations(selected: true) {
            id
            name
          }
          inactiveDeclarations: declarations(selected: false) {
            id
            name
          }
        }
        fragment paintConditionFrag on VehiclePaintCondition {
          hoodDepth
          driverFenderDepth
          driverFrontDoorDepth
          driverRearDoorDepth
          driverQuarterPanelDepth
          passengerFenderDepth
          passengerFrontDoorDepth
          passengerRearDoorDepth
          passengerQuarterPanelDepth
          trunkDepth
          roofDepth
        }
        fragment tireConditionFrag on VehicleTireCondition {
          tirePressureMonitoringSystem
          tirePressureMonitoringSystemPhotoUrl(width: 320, height: 190)
          fourMatchingTires
          afterMarketTires
          winterTires
          studdedWinterTires
          secondSetOfTires
          secondSetOfTiresHasRims
          generalCondition
          driverFrontBrand
          driverFrontSize
          driverFrontTread {
            formatted
          }
          driverRearBrand
          driverRearSize
          driverRearTread {
            formatted
          }
          passengerFrontBrand
          passengerFrontSize
          passengerFrontTread {
            formatted
          }
          passengerRearBrand
          passengerRearSize
          passengerRearTread {
            formatted
          }
        }
        ${locationFragment}
        ${repsFragment}
      `,
      variables: {
        inventoryItemId,
      },
    },
  });
};

export const archiveInventoryItem = ({ inventoryItemId = null, archive = false }) => {
  return ApiRequest<{ inventoryItemArchive: Pick<InventoryItem, 'archived'> }>({
    data: {
      query: `
        mutation($inventoryItemId: String!, $archive: Boolean!) {
          inventoryItemArchive(inventoryItemId: $inventoryItemId, archive: $archive) {
            archived
          }
        }
      `,
      variables: {
        inventoryItemId,
        archive,
      },
    },
  });
};

export const getConsignerLocations = ({ consignerId }: Pick<QuerylocationConnectionArgs, 'consignerId'>) =>
  ApiRequest<{ locationConnection: LocationConnection }>({
    data: {
      operationName: 'getConsignerLocations',
      query: `
      query($consignerId: [String]) {
        locationConnection(first: 500, after: "0", consignerId: $consignerId) {
          edges {
            node {
              id
              name
              public
              address1
              address2
              city
              regionCode
              zipCode
            }
          }
        }
      }
    `,
      variables: {
        consignerId,
      },
    },
  });

export const updateInventoryItemLocations = ({ inventoryItemId = null, locationId = null, pickupLocationId = null }) =>
  ApiRequest<{ inventoryItemChangeLocation: Pick<InventoryItem, 'id' | 'location' | 'pickupLocation'> }>({
    data: {
      operationName: 'updateInventoryItemLocations',
      query: `
      mutation($inventoryItemId: String!, $locationId: String, $pickupLocationId: String) {
        inventoryItemChangeLocation(inventoryItemId: $inventoryItemId, locationId: $locationId, pickupLocationId: $pickupLocationId) {
          id
          location { ...location }
          pickupLocation { ...location }
        }
      }
      ${locationFragment}
    `,
      variables: {
        inventoryItemId,
        locationId,
        pickupLocationId: nullifyEmptyString(pickupLocationId || locationId), // Default to `locationId`
      },
    },
  });

export interface MutationUpdateInventoryItemStatusArgs extends Omit<MutationinventoryItemUpdateArgs, 'vehicle'> {
  status: VehicleStatus | null;
}

export const updateInventoryItemStatus = ({
  inventoryItemId,
  status = null,
}: MutationUpdateInventoryItemStatusArgs) => {
  return ApiRequest<{ inventoryItemUpdate: VehicleBuilder }>({
    data: {
      operationName: 'updateInventoryItem',
      query: `
        mutation (
          $inventoryItemId: String!,
          $status: VehicleStatus,
        ){
          inventoryItemUpdate(
            inventoryItemId: $inventoryItemId,
            vehicle: {
              status: $status,
              persist: true,
            }
          )
            {
            ... on VehicleBuilder {
                vehicle {
                  id,
                  status
                }
              }
            }
         }
      `,
      variables: {
        inventoryItemId,
        status,
      },
    },
  });
};

export const saveInventoryItemFees = ({
  inventoryItemId = null,
  transportFee = null,
  transportFeeText = null,
  mechanicalFee = null,
  mechanicalFeeText = null,
  reconditioningFee = null,
  reconditioningFeeText = null,
  storageFee = null,
  storageFeeText = null,
  redemptionFee = null,
  redemptionFeeText = null,
  marshallingFee = null,
  marshallingFeeText = null,
  miscFee = null,
  miscFeeText = null,
  registrationFee = null,
  registrationFeeText = null,
  fuelFee = null,
  fuelFeeText = null,
}) => {
  return ApiRequest<{ inventoryItemFeeUpdate: Pick<Vehicle, 'inventoryItemFee'> }>({
    data: {
      query: `
      mutation (
        $inventoryItemId:String!
        $transportFee: Float
        $transportFeeText: String
        $mechanicalFee: Float
        $mechanicalFeeText: String
        $reconditioningFee: Float
        $reconditioningFeeText: String
        $storageFee: Float
        $storageFeeText: String
        $redemptionFee: Float
        $redemptionFeeText: String
        $marshallingFee: Float
        $marshallingFeeText: String
        $miscFee: Float
        $miscFeeText: String
        $registrationFee: Float
        $registrationFeeText: String
        $fuelFee: Float
        $fuelFeeText: String

      ) {
        inventoryItemFeeUpdate(
          inventoryItemId: $inventoryItemId
          transportFee: $transportFee
          transportFeeText: $transportFeeText
          mechanicalFee: $mechanicalFee
          mechanicalFeeText: $mechanicalFeeText
          reconditioningFee: $reconditioningFee
          reconditioningFeeText: $reconditioningFeeText
          storageFee: $storageFee
          storageFeeText: $storageFeeText
          redemptionFee: $redemptionFee
          redemptionFeeText: $redemptionFeeText
          marshallingFee: $marshallingFee
          marshallingFeeText: $marshallingFeeText
          miscFee: $miscFee
          miscFeeText: $miscFeeText
          registrationFee: $registrationFee
          registrationFeeText: $registrationFeeText
          fuelFee: $fuelFee
          fuelFeeText: $fuelFeeText
        ) {
          ${inventoryItemFees}
        }
      }
    `,
      variables: {
        inventoryItemId,
        transportFee,
        transportFeeText,
        mechanicalFee,
        mechanicalFeeText,
        reconditioningFee,
        reconditioningFeeText,
        storageFee,
        storageFeeText,
        redemptionFee,
        redemptionFeeText,
        marshallingFee,
        marshallingFeeText,
        miscFee,
        miscFeeText,
        registrationFee,
        registrationFeeText,
        fuelFee,
        fuelFeeText,
      },
    },
  });
};

export const saveInventoryItemValues = ({
  isAdding = null,
  inventoryItemId = null,
  valueId = null,
  valueInput = null,
}) => {
  const mutation = isAdding ? 'inventoryItemValueCreate' : 'inventoryItemValueUpdate';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return ApiRequest<{ [key in typeof mutation]?: InventoryItem }>({
    data: {
      operationName: 'saveInventoryItemValues',
      query: `
        mutation(
          ${isAdding ? '$inventoryItemId: String!,' : '$valueId: String!,'}
          $valueInput: ${isAdding ? 'InventoryItemValueInput!' : 'InventoryItemValueUpdateInput!'}
        ) {
          ${mutation}(
            ${isAdding ? 'inventoryItemId: $inventoryItemId' : 'valueId: $valueId'},
            valueInput: $valueInput
          ) {
            ${inventoryItemValues}
          }
        }
      `,
      variables: {
        inventoryItemId,
        valueId,
        valueInput,
      },
    },
  });
};

export const getAuditLog = ({ inventoryItemId = null }) =>
  ApiRequest<{ inventoryItem: Pick<InventoryItem, 'logEntries'> }>({
    data: {
      query: `
      query {
        inventoryItem(inventoryItemId: "${inventoryItemId}") {
          logEntries {
            color
            type
            message
            note
            created
          }
        }
      }
    `,
      variables: {
        inventoryItemId,
      },
    },
  });

export const updateAuctionLocation = ({
  inventoryItemId = null,
  auctionLocationId = '',
}: {
  inventoryItemId?: string | null;
  auctionLocationId?: string;
}) =>
  ApiRequest<{ inventoryItemUpdate: VehicleBuilder }>({
    data: {
      query: `
        mutation {
          inventoryItemUpdate(inventoryItemId:"${inventoryItemId}", vehicle:{ auctionLocationId: "${auctionLocationId}", persist:true }) {
            ...on VehicleBuilder {
              vehicle {
                auctionLocation {
                  ...location
                }
              }
            }
          }
        }
        ${locationFragment}
      `,
    },
  });

export interface MutationAddInventoryItemNote {
  /** List of companies */
  companies: SelectCompanyOption[] | null;
  /** Note content */
  content: string | null;
  /** Inventory Item Id */
  inventoryItemId: string;
  /** True is an auction item */
  isAuctionItem: boolean;
}

export const addInventoryItemNote = ({ inventoryItemId, content, companies = null }: MutationAddInventoryItemNote) => {
  return ApiRequest<{ noteCreate: Note }>({
    data: {
      query: `
        mutation($entityType: NoteEntityType!, $entityId: String!, $content: String!, $companyIds: [String]) {
          noteCreate(entityType: $entityType, entityId: $entityId, content: $content, companyIds: $companyIds) {
            id
            content
            createdBy {
              firstName
              lastName
            }
            created
          }
        }
      `,
      variables: {
        entityType: 'INVENTORY_ITEM',
        entityId: inventoryItemId,
        content,
        companyIds: companies?.map((company) => company?.value),
      },
    },
  });
};

export const auctionItemSellDirect = ({
  inventoryItemId = null,
  auctionId = null,
  companyId = null,
  userId = null,
  price = null,
  asIs = null,
}) =>
  ApiRequest<{ auctionItemSellDirect: Pick<AuctionItem, 'id' | 'status' | 'format'> }>({
    data: {
      operationName: 'auctionItemSellDirect',
      query: `
      mutation(
        $inventoryItemId: String!
        $auctionId: String!
        $companyId: String!
        $userId: String!
        $price: Float!
        $asIs: Boolean!
      ) {
        auctionItemSellDirect (
          inventoryItemId: $inventoryItemId,
          auctionId: $auctionId,
          companyId: $companyId,
          userId: $userId,
          price: $price,
          asIs: $asIs
        ) {
          id
          status
          format
        }
      }
    `,
      variables: {
        inventoryItemId,
        auctionId,
        companyId,
        userId,
        price,
        asIs,
      },
    },
  });
