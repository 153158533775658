import ApiRequest from 'store/shared/api/apiRequest';
import {
  Company,
  FeatureFlag,
  MutationcompanyInvoiceSignatureSetArgs,
  MutationuserUpdateInfoArgs,
  MutationuserUpdatePasswordArgs,
  MutationuserUpdatePreferredLocationDistanceUnitArgs,
  User,
} from 'store/shared/api/graph/interfaces/types';
import { featureFlags } from 'store/shared/api/graph/schema/featureFlags';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { operatingRegions } from 'store/shared/api/graph/schema/operatingRegions';
import { repsFragment } from 'store/shared/api/graph/fragments/reps';
import { substringReplace } from 'utils/stringUtils';
import { supportedLocales } from 'store/shared/api/graph/schema/locales';

export const getUser = () =>
  ApiRequest<{ user: User & { token: string } }>({
    data: {
      operationName: 'getUser',
      query: `
      {
        user {
          token: authenticationToken
          id
          source
          sourceEnum
          title
          firstName
          lastName
          email
          emailVerified
          employee
          cellPhone
          secondaryPhone
          photoUrl(width: 100, height: 100)
          phoneVerified
          channels
          locale
          distanceUnit
          timeZone
          preferredLocation {
            ...location
          }
          created
          sessionActingAsOtherUser
          sessionId
          status
          auctionAccessId
          auctionAccessLastVerified
          omvicRegNumber
          omvicRegExpiryDate
          hasCarfaxUSASession
          companyRelationships {
            count
            list {
              company {
                id
                name
                visibleRegions
                auctionRelationships {
                  list {
                    id
                    auction {
                      id
                      auctionSettings {
                        internalSaleEnabled
                      }
                      featureFlagKeys
                      title
                    }
                    permissions {
                      id
                    }
                    reps {
                      ...reps
                    }
                    status
                  }
                }
                holdback {
                  amount
                  type
                }
                type
                primaryLocation {
                  ...location
                }
              }
              status
              permissions {
                id
                name
              }
              roles {
                permissions {
                  id
                  name
                }
              }
            }
          }
        }
        ${featureFlags}
        ${operatingRegions}
        ${supportedLocales}
      }
      ${locationFragment}
      ${repsFragment}
      `,
    },
  });

export const updateUserInfo = ({
  cellPhone = null,
  email = null,
  firstName = null,
  lastName = null,
  photo = null,
  secondaryPhone = null,
  title = null,
}: MutationuserUpdateInfoArgs) =>
  ApiRequest<{ userUpdateInfo: User }>({
    data: {
      operationName: 'userUpdateInfo',
      query: `
      mutation (
        $cellPhone: String,
        $email: String,
        $firstName: String,
        $lastName: String,
        $photo: String,
        $secondaryPhone: String,
        $title: String,
      ) {
        userUpdateInfo(
          cellPhone: $cellPhone,
          email: $email,
          firstName: $firstName,
          lastName: $lastName,
          photo: $photo
          secondaryPhone: $secondaryPhone,
          title: $title,
        ) {
          id
          cellPhone
          email
          firstName
          lastName
          photoUrl(width: 100, height: 100)
          secondaryPhone
          title
        }
      }
    `,
      variables: {
        cellPhone,
        email,
        firstName,
        lastName,
        photo: substringReplace(photo, 'data:image/png;base64,', ''),
        secondaryPhone,
        title,
      },
    },
  });

export const updateUserPassword = ({
  password = null,
  passwordConfirmation = null,
  logoutDevices = null,
}: MutationuserUpdatePasswordArgs) =>
  ApiRequest<{ userUpdatePassword: Pick<User, 'id'> }>({
    data: {
      query: `
      mutation (
        $password: String,
        $passwordConfirmation: String,
        $logoutDevices: Boolean
      ) {
        userUpdatePassword(
          password: $password,
          passwordConfirmation: $passwordConfirmation,
          logoutDevices: $logoutDevices
        ) {
          id
        }
      }
    `,
      variables: {
        password,
        passwordConfirmation,
        logoutDevices,
      },
    },
  });

export const setCompanyInvoiceSignature = ({
  companyId,
  registrationNumber,
  signature,
}: MutationcompanyInvoiceSignatureSetArgs) =>
  ApiRequest<{ companyInvoiceSignatureSet: Pick<Company, 'id' | 'invoiceSignature'> }>({
    data: {
      query: `
      mutation (
        $companyId: String!,
        $registrationNumber: String,
        $signature: String,
      ) {
        companyInvoiceSignatureSet(
          companyId: $companyId,
          registrationNumber: $registrationNumber,
          signature: $signature
        ) {
          id
          invoiceSignature {
            signatureUrl
            registrationNumber
          }
        }
      }
    `,
      variables: {
        companyId,
        registrationNumber,
        signature,
      },
    },
  });

export const updatePreferredLocation = ({ locationId = null }: MutationuserUpdatePreferredLocationDistanceUnitArgs) =>
  ApiRequest<{ userUpdatePreferredLocationDistanceUnit: Pick<User, 'id'> }>({
    data: {
      query: `
      mutation($locationId: String) {
        userUpdatePreferredLocationDistanceUnit(locationId: $locationId) {
          id
        }
      }
    `,
      variables: {
        locationId,
      },
    },
  });

export const logoutCarfaxUSASession = () =>
  ApiRequest<{ logoutCarfaxUSASession: Pick<User, 'id'> }>({
    data: {
      query: `
        mutation {
          logoutCarfaxUSASession {
            id
          }
        }
      `,
    },
  });

export const getFeatureFlags = () =>
  ApiRequest<{ featureFlags: FeatureFlag[] }>({
    data: {
      operationName: 'getFeatureFlags',
      query: `
        {
          ${featureFlags}
        }
      `,
    },
  });
