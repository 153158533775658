import { Link } from 'react-router';
import { MouseEvent, MouseEventHandler, useCallback, useMemo } from 'react';

import editGlyph from 'glyphs/edit.svg';
import externalGlyph from 'glyphs/external-link.svg';

import Button from 'components/ui/shared/button';
import CarfaxSummary from 'components/sections/inventoryItem/details/conditionReport/carfaxSummary';
import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import SlideOut from 'components/ui/slideOuts/slideOut';
import Sprite from 'components/ui/shared/sprite';
import { CarfaxAnnouncements, CarfaxReportStatus } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { splitOnNewLine } from 'utils/componentUtils';
import { t } from 'utils/intlUtils';

import style from './carfaxSlideOut.scss';

interface Props extends SlideOutComponentProps {
  /** Announcements from carfax report. */
  carfaxAnnouncements?: CarfaxAnnouncements;
  /** True when the slide out is loading. */
  isLoading: boolean;
  /** Whether the slide out is open or not. */
  isOpen: boolean;
  /** Function triggered when slide out closes. */
  onClose: () => void;
  /** Function when edit button clicked. */
  onEdit?: (event: MouseEvent<HTMLButtonElement>) => void;
  /** Function called when view full report link clicked */
  onReportLinkClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

const CarfaxSlideOut = ({ carfaxAnnouncements, isLoading, isOpen, onReportLinkClick, onEdit, onClose }: Props) => {
  const reportStatus = carfaxAnnouncements?.reportStatus ?? CarfaxReportStatus.NO_MVDA_REPORT;
  const reportUrl = carfaxAnnouncements?.reportUrl;

  /**
   * Renders edit icon in slide out header.
   */
  const headerEditButton = useMemo(
    () => (
      <Button
        className={style.editButton}
        dataTestId="edit-button"
        onClick={(event) => {
          onEdit?.(event);
          onClose();
        }}
        theme="none"
      >
        <Sprite glyph={editGlyph} />
      </Button>
    ),
    [onClose, onEdit]
  );

  /**
   * Slide out content rendered when no MVDA report available or expired.
   */
  const renderPlaceholder = useCallback(
    (title: string, message: string) => {
      return (
        <NoResults className={style.placeholder} title={title}>
          <div className={style.message}>{splitOnNewLine(message)}</div>
          {reportUrl && (
            <Link className={style.link} onClick={onReportLinkClick} target="_blank" to={reportUrl}>
              {t('view_full_report')}
              <Sprite className={style.externalIcon} glyph={externalGlyph} />
            </Link>
          )}
        </NoResults>
      );
    },
    [onReportLinkClick, reportUrl]
  );

  /**
   * Slide out content rendered when MVDA report available.
   */
  const renderCarfaxSummary = useMemo(() => {
    return (
      <CarfaxSummary
        announcements={carfaxAnnouncements?.announcements || []}
        className={style.summary}
        onReportLinkClick={onReportLinkClick}
        reportUrl={reportUrl}
      />
    );
  }, [carfaxAnnouncements?.announcements, onReportLinkClick, reportUrl]);

  return (
    <SlideOut
      headerActions={onEdit ? headerEditButton : undefined}
      headerClassName={style.header}
      isOpen={isOpen}
      onClose={onClose}
      title={t('carfax_summary')}
    >
      {reportStatus === CarfaxReportStatus.NO_MVDA_REPORT &&
        renderPlaceholder(t('report_unavailable'), t('report_unavailable_message'))}
      {reportStatus === CarfaxReportStatus.EXPIRED &&
        renderPlaceholder(t('report_expired'), t('report_expired_message'))}
      {reportStatus === CarfaxReportStatus.VALID && renderCarfaxSummary}
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default CarfaxSlideOut;
