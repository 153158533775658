// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","selectLocation":"selectLocation_dfb7578","headerText":"headerText_bef7b35","reserveNotification":"reserveNotification_c771aa6","inputContainer":"inputContainer_ee2b2bf","dollarSign":"dollarSign_e0fef71","startingPriceContainer":"startingPriceContainer_ad62cd8","dropdown":"dropdown_c05211b","placeholderDropdown":"placeholderDropdown_fd4cc4b","subTitle":"subTitle_abc78a9","radioButtons":"radioButtons_cff6a45","radioButtonsContainer":"radioButtonsContainer_f3ba482","radioButtonsAuction":"radioButtonsAuction_b63a67c","fetchingAuctionTimeSlotSpinner":"fetchingAuctionTimeSlotSpinner_a66ef30","footer":"footer_fce6b57","button":"button_d26c264","arrowSprite":"arrowSprite_a870f58","spinnerStyle":"spinnerStyle_ccfff2d","asIsContainer":"asIsContainer_efdba37","asIsCause":"asIsCause_f4b020e","asIsRadioLabel":"asIsRadioLabel_c49b86e"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"selectLocation\":\"selectLocation_dfb7578\",\"headerText\":\"headerText_bef7b35\",\"reserveNotification\":\"reserveNotification_c771aa6\",\"inputContainer\":\"inputContainer_ee2b2bf\",\"dollarSign\":\"dollarSign_e0fef71\",\"startingPriceContainer\":\"startingPriceContainer_ad62cd8\",\"dropdown\":\"dropdown_c05211b\",\"placeholderDropdown\":\"placeholderDropdown_fd4cc4b\",\"subTitle\":\"subTitle_abc78a9\",\"radioButtons\":\"radioButtons_cff6a45\",\"radioButtonsContainer\":\"radioButtonsContainer_f3ba482\",\"radioButtonsAuction\":\"radioButtonsAuction_b63a67c\",\"fetchingAuctionTimeSlotSpinner\":\"fetchingAuctionTimeSlotSpinner_a66ef30\",\"footer\":\"footer_fce6b57\",\"button\":\"button_d26c264\",\"arrowSprite\":\"arrowSprite_a870f58\",\"spinnerStyle\":\"spinnerStyle_ccfff2d\",\"asIsContainer\":\"asIsContainer_efdba37\",\"asIsCause\":\"asIsCause_f4b020e\",\"asIsRadioLabel\":\"asIsRadioLabel_c49b86e\"}";
        // 1728645286673
        var cssReload = require("../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  